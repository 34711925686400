<template>
  <div style="padding: 10px; padding-top: 44px">
    <van-nav-bar
      title="用户隐私协议"
      left-arrow
      @click-left="$router.go(-1)"
      fixed
    />
    <p
      style="
        font-size: 14px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: center;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 14px; font-weight: bold"
        >用户隐私协议</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px">全游云电脑是一</span
      ><span style="font-family: 楷体; font-size: 10.5px">项</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >由杭州子默网络科技有限公司提供的包括云端资源、传输协议和云终端在内云电脑整体服务，在您使用全游云电脑的各项服务时，对于全游云电脑会如何收集、使用和存储您的个人信息，以及您享有何种权利以及如何与我们沟通联系，全游云电脑产品方深知个人信息对您的重要性，将会尽全力保护您的个人信息安全可靠，将通过本政策向您阐述相关事宜。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >请在使用我们的产品或服务前，仔细阅读并了解本《隐私政策》，在确认充分理解并同意后再使用相关产品或服务。一旦您点击同意、确认或者开始使用全游云电脑服务时，即表示您已充分理解并同意本政策。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >定义：</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >个人信息：</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >关联公司：</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >是指一方现在或将来控制、受控制或与其出于共同控制下的任何公司、合法机构以及上述公司、合法机构的合法继承人。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第一条：如何收集和使用您的个人信息</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        text-indent: 0px;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px">1.1</span
      ><span style="font: 7px 'Times New Roman'">&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >当您使用全游云电脑时，将会记录您的账号、密码以及您登录方式对应的基本信息，如手机号、微信OpenID、身份证信息，收集这些信息是为了帮助您完成账号的注册及登录，完成身份验证，保护您账号的安全。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1.2在注册过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供更好的服务和体验。此类信息包括：您的昵称、头像、性别、生日、所在地、教育经历、职业</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >、兴趣爱好、常玩游戏等。如果您不提供以上信息，将不会影响您使用全游云电脑产品服务的基本功能。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1.3当您注册、使用全游云电脑时，为保证账户安全和行为真实有效，我们会对您的登录行为进行验证。在验证时，我们会收集您的验证码。收集这些信息是为了帮助您完成全游账号的注册及登录，完成身份验证，保护您账号的安全。若您不提供上述信息，将无法完成注册，无法正常使用我们的产品或服务。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1.4全游云电脑方将会根据您在软件安装及使用中授予的具体权限，收集您所使用的设备相关信息（例如设备型号、操作系统版本、显卡、声卡、网卡等软硬件特征信息）、设备所在位置相关信息（例如IP
        地址）、网络类型。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1.5当您选择账号免登服务时，可能会收集相关读取文件存储，以帮助您实现免登功能。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1.6当你开通支付功能时，可能会收集你的姓名、银行卡类型及卡号、有效期及银行预留手机号；请您在确认支付前，阅读支付功能的隐私政策和指引。您可以拒绝提供这些信息。如果您不提供这些信息，将无法获得便捷支付服务，但不会影响使用本产品或服务的基本业务功能。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1.7当您在使用游戏搜索功能，并在搜索结果中启动游戏时，我们可能会收集您的游戏搜索记录和游戏启动记录，以便为您提供快速启动和游戏个性化推荐功能。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >另外，根据相关法律法规及国家标准，以下情形中，我们可能会收集、使用您的相关个人信息无需征求您的授权同意： </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >1) 与本公司履行法律法规规定的义务相关的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"> </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2) 与国家安全、国防安全直接相关的； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >3) 与公共安全、公共卫生、重大公共利益直接相关的； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >4) 与犯罪侦查、起诉、审判和判决执行等直接相关的； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >5)
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >6) 所涉及的个人信息是您自行向社会公众公开的； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7)
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >8)用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >9)为新闻单位且其在开展合法的新闻报道所必需的； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >10)为学术研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第二条：如何共享、转让、公开披露您的个人信息</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px">2.1 共享</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >除全游云电脑方及其管理公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外： </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.1.1获得您的明确同意后，会与其他方共享您的个人信息。 </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.1.2根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。 </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.1.3全游云电脑方只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.1.4与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >2.2 转让</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >全游云电脑方不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.2.1获得您的明确同意后，我们会向其他方转让您的个人信息； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.2.2在涉及合并、收购或破产清算时，如涉及到个人信息转让，将会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则将要求该公司、组织重新向您征求授权同意。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >2.3公开披露</span
      ><span style="font-family: 楷体; font-size: 10.5px"> </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.3.1获得您明确同意后；</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >2.3.2基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第三条：如何保护您的个人信息</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >3.1全游云电脑方将使用符合业界标准的安全防护措施保护您提供的个人信息，
        防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。并采取一切合理可行的措施，
        保护您的个人信息。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >3.2采取一切合理可行的措施，确保未收集无关的个人信息。在达成本政策所述目的所必需的期限内和法律规定的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >3.3互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，并强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，保证您的账号安全。
      </span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >3.4将不定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >3.5在不幸发生个人信息安全事件后，全游云电脑方将按照法律法规的要求，及时向您告知：
        安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第四条：您的权利 </span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >4.1访问您的个人信息</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >您有权访问您的个人信息，法律法规规定的例外情况除外。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >4.2更正您的个人信息</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >当您发现您的账户信息有错误时，您有权要求我们做出更正。如果您无法更正的，您可以随时通过协议约定邮箱与我们取得联系。我们将在
        15个工作日内回复您的更正请求。 </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和网络安全所收集的您的上网信息、您使用游戏软件或网页游戏时产生的游戏相关信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >4.3删除您的个人信息</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >在以下情形中，您可以向我们提出删除个人信息的请求：</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"> </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（1）如果我们处理个人信息的行为违反法律法规； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（2）如果我们收集、使用您的个人信息，却未征得您的同意； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（3）如果我们处理个人信息的行为违反了与您的约定； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（4）如果您不再使用我们的产品或服务，或您注销了账号； </span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（5）如果我们不再为您提供产品或服务。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >4.4改变您授权同意的范围 </span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收
        集和使用，您可以随时给予或收回您的授权同意。当您收回同意后，我们将不再处理相应
        的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >4.5个人信息主体注销账户</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >您随时可注销此前注册的账户，您可以直接联系客服进行注销，注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除或者匿名化处理您的个人信息，法律法规另有规定的除外。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >您在账户注销后仍应对您在账号注销前使用全游云电脑产品服务期间的行为承担相应责任；</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >注销成功后，我们将为用户您保留十五个工作日的账号相关服务信息，若您自注销成功之日起十五个工作日内未通过客服申请撤销注销账号服务的，您的账号信息、个人身份证信息、交易记录、会员权益等将无法恢复或提供；</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >用户理解并接受，由于非全游云电脑方的原因引起的账户信息丢失、泄露等导致公司无法提供相关服务信息的，公司对此不承担任何责任。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >4.6</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。原则上将在15天内做出答复。对于您合理的请求，原则上不收取费用，但对多次重复、超出合理限度的请求，将视情收取一定成本费用。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px"
        >在以下情形中，将无法响应您的请求：</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（1）与个人信息控制者履行法律法规规定的义务相关的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（2）与国家安全、国防安全直接相关的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（3）与公共安全、公共卫生、重大公共利益直接相关的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（4）与犯罪侦查、起诉、审判和执行判决等直接相关的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益
        但又很难得到 本人同意的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >（8）涉及商业秘密的。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第五条 如何处理未成年人的个人信息</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >5.1若您是未满18周岁的未成年人，在使用“全游云电脑”及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >5.2根据国家相关法律法规的规定保护未满18周岁的未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未满18周岁的未成年人所必要的情况下收集、使用、储存、共享、转让或披露未满18周岁的未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未满18周岁的未成年人的个人信息，则会设法尽快删除相关信息。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >5.3若您是未满18周岁的未成年人的监护人，当您对您所监护的未满18周岁的未成年人的个人信息有相关疑问时，请通过公司本隐私政策公示的联系方式与我们联系。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第六条：转移</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >全游云电脑方在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第七条：更新</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >&#xa0;</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.1对隐私政策的可能变更，但未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2您登录或者使用时以推送通知、弹窗等形式向您展示变更后的政策。对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2.1服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2.2在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并
        购等引起的所有者变更等。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2.3个人信息共享、转让或公开披露的主要对象发生变化。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2.4您参与个人信息处理方面的权利及其行使方式发生重大变化。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2.5我们负责处理个
        人信息安全的责任部门、联络方式及投诉渠道发生变化时。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >7.2.6个人信息安全影响评估报 告表明存在高风险时。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第八条：联系</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >8.1如果您对本隐私政策有任何疑问、意见或建议，通过邮箱【service@zimonet.com】与我们联系。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >8.2我们将尽快审核所涉问题，一般情况下，我们会在验证您的用户身份后的15个工作日内回复。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >8.3如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，
        您还可以通过以下外部途径寻求解决方案：向您所在国家的监管部门进行投诉或举报，例
        如中国的网信、电信、公安及市场监管等部门。</span
      ><span style="font-family: 楷体; font-size: 10.5px">&#xa0;</span>
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第九条：争议处理</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >因本协议引起的任何分歧和争议，双方应当友好协商解决。协商不成的，应提交杭州市拱墅区人民法院通过诉讼解决。</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >第十条：生效</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: 楷体; font-size: 10.5px"
        >本协议自您注册完成时生效并依据本协议条款对您产生约束力。</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: right">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: bold"
        >杭州子默网络科技有限公司</span
      >
    </p>
    <p style="margin: 5px 0px; text-align: right">
      <span style="font-family: 楷体; font-size: 10.5px; font-weight: normal">
        2022年7月1日</span
      >
    </p>
    <p
      style="
        font-size: 10.5px;
        line-height: 150%;
        margin: 0px;
        orphans: 0;
        text-align: justify;
        widows: 0;
      "
    >
      <span style="font-family: Calibri; font-size: 10.5px">&#xa0;</span>
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
